import React from "react";
import "./TabProductStatus.scss";
import Axios from "axios";
import { domain } from "../../url";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import noreceivedproducts from "../../images/notify.svg";


export const TabProductStatus = () => {
  const [inquiryDetail,setInquiryDetail] = React.useState()

  React.useEffect(()=>{
 


    const fetchData = async () => {
      try {
        const token = localStorage.getItem("jwtToken"); // Replace with your actual JWT token

        const response = await fetch(`${domain}/api/supplier_inquiries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
      const completedOrder =  responseData?.inquiries?.filter((item)=> item?.status_name === "Completed")
      console.log("inquiryDetail", completedOrder)

          setInquiryDetail(completedOrder);

        } else {
          console.log('Request failed with status:', response.status);
        }
      } catch (error) {
        console.log('Error:', error);
      }

    };

    
    fetchData()
  },[])



  return (
    <div className="tab-product-status">
      <h5 className="title-content">Received Products</h5>
      {inquiryDetail?.length > 0 ?
      <>
      {inquiryDetail?.map((item,index)=>{
        const productdata = item?.product[0]
        return(
          <div className="tab-panel-card" key={index}>
          <div className="product-status-content">
            <div style={{width:"15%"}}>
            <img
              loading="lazy"
              src={productdata?.image}
              alt="Not Found"
            />
            </div>

            <div className="sub-content border-rt" style={{width:"25%"}}>
              <div>
                <p className="title-content mb-10">{productdata?.name}</p>
                <p className="pricing mb-10">Quantity: <span>{productdata?.quantity}</span></p>

              </div>
            </div>
            <div className="content-wrap" style={{width:"50%"}}>
            <div className="sub-content ">
              <div>
                {/* <p className="title-content">{productdata?.description}</p> */}
                <div dangerouslySetInnerHTML={{ __html: productdata?.description }}></div>

              </div>
            </div>

            <div className="sub-content border-left">
              <div>
                {/* <p className="pricing mb-10">Total</p>
                <p className="title-content ">RS.2000</p> */}
              </div>
            </div>
            </div> 

          <div style={{width:"10%"}}>
            <button className="main-btn">View Order</button>
            </div>
          </div>
        </div>
        )
      })}
</>
 : 
 
 <NoDataContent image={noreceivedproducts} info="No any recieved products till date" />}


      <h5 className="title-content">Recently Completed Order</h5>

      <div className="tab-panel-card">
        <div className="border-wrap">
          <div className="product-listview">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />

            <h5 className="title-content">HD Smart Hitachi TV</h5>
          </div>

          <div className="product-listview">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />

            <h5 className="title-content">HD Smart Hitachi TV</h5>
          </div>
          <div className="product-listview">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />

            <h5 className="title-content">HD Smart Hitachi TV</h5>
          </div> <div className="product-listview">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />

            <h5 className="title-content">HD Smart Hitachi TV</h5>
          </div> <div className="product-listview">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />

            <h5 className="title-content">HD Smart Hitachi TV</h5>
          </div> <div className="product-listview">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />

            <h5 className="title-content">HD Smart Hitachi TV</h5>
          </div> <div className="product-listview">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />

            <h5 className="title-content">HD Smart Hitachi TV</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
