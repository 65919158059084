import React, { Component } from "react";
import "../../assets/css/materialize.css";
import "../../assets/css/dashboard.css";
import "../../assets/css/style.css";
import "../../assets/css/choices.css";
import "../../assets/css/custom.css";
import "../../assets/css/style-horizontal.css";
import "../../style/dealOfTheWeek.scss";
import BottomCardShimmer from "./bottomcardShimmer";
// import $ from "jquery";

class BottomCard extends Component {
  card = () => {
    return (
      <>
        {this.props.isShimmer ? (
          <>
            "<BottomCardShimmer />"
          </>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col s12 m12 l12 p-0">
                <div className=" card  b-r-12">
                  <div className="card-content col l3 s6 p-0">
                    <div className="bottom-cards center-align-sm bottom-cart-b-1">
                      <img
                        loading="lazy"
                        src={require("../../images/sell.svg")}
                        alt="img"
                      />
                      <p>Sell on Najik</p>
                    </div>
                  </div>
                  <div className="card-content col l3 s6 p-0">
                    <div className="bottom-cards center-align-sm bottom-cart-b-2 ">
                      <img
                        loading="lazy"
                        src={require("../../images/marketing.svg")}
                        alt="img"
                      />
                      <p>Advertise</p>
                    </div>
                  </div>
                  <div className="card-content col l3 s6 p-0">
                    <div className="bottom-cards center-align-sm bottom-cart-b-2 ">
                      <img
                        loading="lazy"
                        src={require("../../images/help-center.svg")}
                        alt="img"
                      />
                      <p>Help Center</p>
                    </div>
                  </div>
                  <div className="card-content col l3 s6 p-0">
                    <div className="bottom-cards center-align-sm bottom-cart-b-3 ">
                      <img
                        loading="lazy"
                        src={require("../../images/secure.svg")}
                        alt="img"
                      />
                      <p>Secure Payment</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  render() {
    return <section className="bottom-card">{this.card()}</section>;
  }
}
export default BottomCard;
