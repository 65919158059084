import React, { useEffect, useRef, useState } from "react";
import ChatInput from "./ChatInput";
import "./Chat.scss";
import Axios from "axios";
import { domain, websocketDomain } from "../../url";
import ActionCable from "actioncable";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { current } from "immer";

const CableApp = {
  cable: ActionCable.createConsumer(`${websocketDomain}`),
};
export const Chat = () => {
  const [userlist, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [messageHistory, setMessageHistory] = useState("");
  const [messages, setMessages] = useState([]);
  const endMessageRef = useRef(null);
  const [userId, setUserId] = useState();
  const [recipientId, setRecipientId] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [load, setLoad] = useState(false);
  const location = useLocation();

  const supplierDetails = location?.state?.supplierDetails;
  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    const id = localStorage.getItem("id");
    setUserId(id);
    if (id !== undefined) {
      Axios.get(`${domain}api/chat_history?user=${id}`).then((res) => {
        setSelectedUser(res.data[0]?.chat);
        setUserList(res?.data);
        setLoad(true);
      });
    }
  }, []);

  useEffect(() => {
    if (load) {
      if (userlist.length > 0) {
        const filterSupplierId = userlist?.find(
          (item) =>
            item?.chat?.user_names[0]?.id ===
            supplierDetails?.chat?.user_names[0]?.id
        );
        if (filterSupplierId) {
          viewChat(filterSupplierId);
        } else {
          setUserList([...userlist, supplierDetails]);
          viewChat(supplierDetails);
        }
      } else {
        setUserList([...userlist, supplierDetails]);
        viewChat(supplierDetails);
      }
    }
  }, [load]);
  // }, [userlist, supplierDetails]);

  useEffect(() => {
    endMessageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [messages]);

  const actionCable = (item) => {
    const current_user = localStorage.getItem("id");
    const recipient_id = item.chat.user_names[0]?.id;

    if (subscription) {
      subscription.unsubscribe();
    }
    console.log(current_user, recipient_id);
    var subscriptionInstance = CableApp.cable.subscriptions.create(
      {
        channel: "ChatChannel",
        sender_id: { current_user },
        recipient_id: { recipient_id },
        uuid: "08c7b14e-ff58-4be3-9e6e-131c218e6f03",
      },
      {
        received: (reply) => {
          setMessages((messages) => [...messages, reply]);
        },
      }
    );
    setSubscription(subscriptionInstance);
  };

  useEffect(() => {
    if (messageHistory !== "" || undefined) {
      const prevMessages = messageHistory?.map((item) => {
        return item.message;
      });

      setMessages(prevMessages);
    }
  }, [messageHistory]);

  useEffect(() => {
    if (
      selectedSupplier !== "" &&
      selectedSupplier !== undefined &&
      userId !== undefined
    ) {
      Axios.get(
        `${domain}api/message_history?chat_id=${selectedSupplier}&user=${userId}`
      ).then((res) => {
        setMessageHistory(res.data);
      });
    }
  }, [selectedSupplier, userId]);

  const viewChat = (item) => {
    setRecipientId(item.chat.user_names[0]?.id);
    setSelectedUser(item.chat);
    setSelectedSupplier(item.chat.id);
    actionCable(item);
  };

  return (
    <div className="container ">
      <div className="chat-card-section">
        <div className="chat-display chat-section">
          <div className="chat-list-block">
            <div className="chat-list-header">
              <p>Chats</p>
            </div>
            {userlist?.map((item, index) => {
              return (
                <div>
                  <div
                    className={`chat-user ${
                      parseFloat(selectedSupplier) === item?.chat?.id &&
                      "active"
                    }`}
                    onClick={() => {
                      viewChat(item);
                    }}
                    key={index}
                  >
                    <img
                      src={require("../../images/user-img.png")}
                      alt="user-img"
                    />
                    <p> {item?.chat?.user_names[0]?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className=" message-list-block">
            <div className="message-list-header">
              <img src={require("../../images/user-img.png")} alt="user-img" />
              <p>
                {selectedUser !== ""
                  ? selectedUser?.user_names[0].name
                  : "loading..."}
              </p>
            </div>
            <div className="message-list">
              {messages?.map((item, index) => {
                return (
                  <div>
                    {item?.user_id !== parseFloat(userId) ? (
                      <div className="sender_message-card" key={index}>
                        <img
                          className="chat-photo"
                          src={require("../../images/user-img.png")}
                          alt="chat-img"
                        />
                        <p>{item.content}</p>
                      </div>
                    ) : (
                      <div className="recipient-message-card">
                        <p>{item.content}</p>
                        <img
                          className="chat-photo"
                          src={require("../../images/user-img.png")}
                          alt="chat-img"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              <div ref={endMessageRef} />
            </div>
            <ChatInput
              current_user={userId}
              recipientId={recipientId}
              messages={messages}
              setMessages={setMessages}
              cable={"CableApp.cable"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Chat;
