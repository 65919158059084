import React, { Component } from "react";
import MidHeader from "../pages/Home/MidHeader";
// import BottomHeader from "../pages/Home/BottomHeader";
// import TopHeader from "../pages/Home/TopHeader";
import MegaMenu from "../pages/Home/MegaMenu";
import { withRouter } from "react-router-dom";
// import CategoryHeader from "../pages/categoryHeader";
import Header from "../pages/HomePage/MobileView/header";
import MobileInsideHeader from "../pages/Parts/MobileInsideHeader";
import {
  // BrowserView,
  // isBrowser,
  isMobile,
} from "react-device-detect";

class Headerr extends Component {
  render() {
    if (isMobile) {
      return (
        <>
          {this.props.location.pathname === "/" ? (
            <Header />
          ) : (
            <MobileInsideHeader />
          )}
        </>
      );
    }
    return (
      <header className="page-topbar nav-sticky" id="header">
        <div className="navbar ">
          {/* <TopHeader /> */}
          <MidHeader />
          {/* <BottomHeader /> */}
          <MegaMenu />
          {/* <CategoryHeader /> */}
        </div>
      </header>
    );
  }
}
export default withRouter(Headerr);
