import React, { useEffect, useState } from "react";
import "./TabProduct.scss";
import Axios from "axios";
import { domain } from "../../url";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import noviewedimg from "../../images/notify.svg";

export const TabProduct = () => {
  const getRecentlyViewed = localStorage.getItem("recentlyViewedProducts");
  // const formattedData = getRecentlyViewed.split(",").map(item => item.trim()).join(", ");
  const formattedData = getRecentlyViewed.replace(/[\[\] ]/g, "");
  console.log("formatteddata", formattedData);
  // console.log("viewdata",data)
  const [productData, setProductData] = useState();

  useEffect(() => {
    if (formattedData) {
      Axios.get(
        `${domain}/api/recently_viewed?product_ids=${formattedData}`
      ).then((res) => {
        setProductData(res.data.products);
      });
    }
  }, [formattedData]);

  return (
    <div>
      <div className="tab-product-section">
        <h5 className="title-content container">Recently viewed Products</h5>
        {productData?.length > 0 ? (
          <>
            <div className="product-container">
              {productData?.slice(0, 8).map((item, index) => {
                return (
                  <a
                    href={`/product-details/${item?.slug}`}
                    className="tab-panel-card "
                    key={index}
                  >
                    <img loading="lazy" src={item?.image} alt="Not Found" />
                    <div>
                      <p className="title-content">{item?.name}</p>

                      {/* <h5 className="pricing">RS.100 <span>/ Piece</span></h5> */}
                    </div>
                  </a>
                );
              })}
            </div>
          </>
        ) : (
          <NoDataContent
            image={noviewedimg}
            info="You haven't viewed any products yet"
          />
        )}
      </div>

      <div className="tab-product-section">
        <h5 className="title-content container">Recommended Products</h5>
        <div className="product-container">
          <div className="tab-panel-card ">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />
            <div>
              <p className="title-content">Flower printed notebook</p>

              {/* <h5 className="pricing">RS.100 <span>/ Piece</span></h5> */}
            </div>
          </div>
          <div className="tab-panel-card ">
            <img
              loading="lazy"
              src={require("../../images/architect.jpg")}
              alt="Not Found"
            />
            <div>
              <p className="title-content">Flower printed notebook</p>

              {/* <h5 className="pricing">RS.100 <span>/ Piece</span></h5> */}
            </div>
          </div>
          <div className="tab-panel-card ">
            <img
              loading="lazy"
              src={require("../../images/coffee.jpg")}
              alt="Not Found"
            />
            <div>
              <p className="title-content">Flower printed notebook</p>

              {/* <h5 className="pricing">RS.100 <span>/ Piece</span></h5> */}
            </div>
          </div>
          <div className="tab-panel-card ">
            <img
              loading="lazy"
              src={require("../../images/17869.png")}
              alt="Not Found"
            />
            <div>
              <p className="title-content">Flower printed notebook</p>
              {/* <h5 className="pricing">RS.100 <span>/ Piece</span></h5> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
