import React, { useState } from "react";
import { domain } from "../../url";

function ChatInput({ current_user, recipientId, messages, setMessages }) {
  const [chatInput, setChatInput] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (chatInput !== "") {
      fetch(`${domain}api/create_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: chatInput,
          recipient_id: recipientId,
          sender_id: current_user,
          user_type: "sender",
          uuid: "08c7b14e-ff58-4be3-9e6e-131c218e6f03",
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setChatInput("");
          setMessages([...messages, data]);
        })
        .catch((error) => {
          console.error("Fetch error", error);
        });
    }
  }

  return (
    <div className="chat-input">
      <form onSubmit={handleSubmit}>
        <div className="d-flex align-items-center message-send">
          <textarea
            name="chat_input"
            value={chatInput}
            rows="2"
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button type="submit" className=" send-btn">
            <i class="far fa-paper-plane"></i>
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatInput;
