import React from "react" ;
import "./TabDetails.scss";

export const TabDetails = (props)=>{
    return(
        <div className="tab-details-section">
        <div className="row">
          <div className="col m6">
            <div className="user-info">
              <i class="far fa-user"></i>
              <div className="details-info">
                <p>Name</p>
                <h5>{props?.userDetails?.name}</h5>
              </div>
            </div>
          </div>


          <div className="col m6">
            <div className="user-info">
              <i class="far fa-envelope"></i>
              <div className="details-info">
                <p>Email Address</p>
                <h5>{props?.userDetails?.email}</h5>
              </div>
            </div>
          </div>

          <div className="col m6">
            <div className="user-info">
              <i class="fas fa-globe"></i>
              <div className="details-info">
                <p>Country</p>
                <h5>Nepal</h5>
              </div>
            </div>
          </div>

          <div className="col m6">
            <div className="user-info">
              <i class="fas fa-phone"></i>
              <div className="details-info">
                <p>Contact</p>
                <h5>{props?.userDetails?.contact}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
}