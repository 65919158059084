import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { domain } from "../../url";
import { useQuery } from "react-query";
import ImageLoader from "../../Component/ImageLoader";

const MegaMenu = () => {
  const getDashMenu = async () => {
    return axios.get(`${domain}api/dashboard_menu`);
  };
  const [isMenuShown, setisMenuShown] = useState(false);
  const [isViewAllShown, setIsViewAllShown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { data } = useQuery("dashboardMenu", getDashMenu);
  const megaMenuData = data?.data[0];

  // const transitionOptions = {
  //   transitionName: "example",
  //   transitionEnterTimeout: 1000,
  //   transitionLeaveTimeout: 1000,
  // };
  return (
    <div className="mega-menu-wrapper sticky">
      <div className="container">
        <div className="mega-menu">
          {megaMenuData?.["menu"]
            ?.filter((data) => data.children !== null)
            ?.slice(0, 7)
            ?.map((main, index) => (
              <div
                key={index}
                className="category"
                onClick={() => {
                  setActiveIndex(index);
                  setisMenuShown((prevIsMenuShown) => !prevIsMenuShown);
                  setIsViewAllShown(false);
                }}
              >
                <ImageLoader
                  images={main.urls?.icon}
                  className={"mr-5 menu-img"}
                />
                {/* {main.urls.icon == null ? (
                    <EquipmentPlaceholder />
                  ) : (
                    <img
                      loading="lazy"
                      className="mr-5 menu-img"
                      src={main.urls.icon}
                      alt="img"
                    />
                  )} */}
                <div className="category-p">
                  <p>{main.text}</p>
                </div>
              </div>
            ))}

          {isMenuShown ? (
            <div
              className="menu-content "
              // onMouseEnter={() => setisMenuShown(true)}
              onMouseLeave={() => setisMenuShown(false)}
              onClick={() => {
                setisMenuShown((prevIsMenuShown) => !prevIsMenuShown);
              }}
            >
              <div className="">
                <div className="megamenu-borderbg 1">
                  <div className="container d-flex">
                    {megaMenuData?.["menu"]
                      ?.filter((data) => data.children !== null)
                      ?.[activeIndex]?.children.filter(
                        (data) => data.children !== null
                      )
                      ?.map((sub, idx) => (
                        <ul key={idx}>
                          <li
                            className="menu-header"
                            style={{ listStyleType: "none" }}
                          >
                            {sub.text}
                          </li>
                          {sub.children.map((ternary, index) => (
                            <Link to={`/product-listing/${ternary.href}`}>
                              <li
                                onClick={() => setIsViewAllShown(false)}
                                key={index}
                                className="menu-item"
                              >
                                {ternary.text}
                              </li>
                            </Link>
                          ))}
                        </ul>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="category">
            <img
              src={require("../../images/viewAll.png")}
              alt="img"
              className="mr-10"
            />
            <div className="category-p">
              <p
                onClick={() => {
                  setisMenuShown(false);
                  setIsViewAllShown(
                    (prevIsViewAllShown) => !prevIsViewAllShown
                  );
                }}
              >
                View All Categories
              </p>
            </div>
          </div>
          {isViewAllShown ? (
            <div
              className="menu-content "
              // onMouseEnter={() => setIsViewAllShown(true)}
              // onMouseLeave={() => setIsViewAllShown(false)}
            >
              <div className="">
                <div
                  className="megamenu-borderbg 2"
                  // onMouseLeave={() => setIsViewAllShown(false)}
                >
                  <div className="container d-flex flex-column flex-wrap mb-1">
                    {megaMenuData?.["view_all"]?.map((sub, idx) => (
                      <ul className="w-20 mb-0" key={idx}>
                        <Link to={sub.href}>
                          <li
                            className="menu-item p-0 catgories-list-megamenu"
                            onClick={() => setIsViewAllShown(false)}
                          >
                            {sub.text}
                          </li>
                        </Link>

                        {/* {sub.children.map((ternary, index) => (
                            <Link to={`/product-listing/${ternary.href}`}>
                              <li
                                onClick={() => setisMenuShown(false)}
                                key={index}
                                className="menu-item"
                              >
                                {ternary.text}
                              </li>
                            </Link>
                          ))} */}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
