import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { debounce } from "throttle-debounce";
import axios from "../../services/axiosInstance/najikAdmin";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "../../../src/style/searchComponent.scss";
import { components } from "react-select";

const productDropdownOptions = [
  { value: "products", label: "Products" },
  // { value: "suppliers", label: "Suppliers" },
];
const productDropdownStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    border: "none",
    display: " flex",
    height: 50,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  placeHolder: (provided) => ({
    ...provided,
    top: "75%",
    fontSize: "12px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 12px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),

  menuList: (provided) => ({
    ...provided,
    color: "black",
    lineHeight: "20px",
  }),
};
const searchStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    border: "none",
    display: " flex",
    height: 50,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    top: "50%",
    fontSize: "12px",
    lineHeight: "12px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    lineHeight: "35px",
    color: "black",
  }),
  menuList: (provided) => ({
    ...provided,
    color: "black",
    lineHeight: "20px",
  }),
};

class SearchComponent extends Component {
  state = {
    selectedOption: "",
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    const text = e.currentTarget.querySelector('input[type="text"]').value;
    this.props.history.push(
      `/product-listing/search?keyword=${text.split("in")[0].trim()}`
    );
    window.location.replace(`/product-listing/search?keyword=${text.split("in")[0].trim()}`)
  };
  onSearchChange = (option) => {
    this.setState({
      selectedOption: option,
    });
    if (
      this.props.isMobile 
      // ||
      // this.searchTypeRef.state.value.value === "products"
    ) {
      this.props.history.push(
        `/product-listing/${option.slug}?keyword=${option.label
          .split("in")[0]
          .trim()}`
      );
    } else {
      this.props.history.push(`/product-listing/${option.slug}?supplier=true`);
    }
    this.setState({
      selectedOption: "",
    });
  };
  fetchData = debounce(50, false, (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    } else {
      // if (
      //   this.props.isMobile 
      //   ||
      //   this.searchTypeRef.state.value.value === "products"
      // ) {
        this.searchApiByProducts(inputValue, callback);
      // } 
      // else {
        // axios
        //   .get("/api/search_products_by_supplier?keyword=" + inputValue)
        //   .then((resp) => {
        //     const data = resp.data.map((item) => ({
        //       label: `${item.company_name}`,
        //       value: `${item.company_name}`,
        //       slug: item.slug,
        //     }));
        //     callback(data);
        //   })
        //   .catch((error) => {
        //     console.log(error, "catch the hoop");
        //   });
      // }
    }
  });

  searchApiByProducts = (inputValue, callback) => {
    axios
      .get("/api/search_products_by_category?keyword=" + inputValue)
      .then((resp) => {
        const data = resp.data.map((item) => ({
          label: `${item.name} in ${item.category_name}`,
          value: `${item.name} in ${item.category_name}`,
          slug: item.category_slug,
        }));
        callback(data);
      })
      .catch((error) => {
        console.log(error, "catch the hoop");
      });
  };

  render() {
    return (
      <div className="inner-form">
        {this.props.isMobile ? null : (
          <div className="input-field first-wrap">
            <div className="input-select ">
              {/* <Select
                id="category"
                disabled="true"
                ref={(ref) => {
                  (this.searchTypeRef = ref)}}
                defaultValue={{ label: "Products", value: "products" }}
                options={productDropdownOptions}
              /> */}

              Search
            </div>
          </div>
        )}
        <form
          onSubmit={this.onSearchSubmit}
          className="input-field second-wrap p-relative"
        >
          <AsyncSelect
            components={{
              //this helps to not show the menu when there is no option
              Menu: (props) => {
                if (props.options.length === 0) return null;
                return <components.Menu {...props} />;
              },
            }}
            loadOptions={this.fetchData}
            placeholder={"Search for anything, anywhere..."}
            value={this.state.selectedOption}
            onChange={this.onSearchChange}
            styles={this.props.searchStyle ?? searchStyle}
          />
        </form>
      </div>
    );
  }
}

export default withRouter(SearchComponent);
