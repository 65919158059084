import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { domain } from "../../url";
import "../../style/Notification/notification.scss";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    if (localStorage.getItem("userId") === null) {
      const uint32 = window.crypto
        .getRandomValues(new Uint32Array(1))[0]
        .toString(16);
      const timestamp = new Date().valueOf();
      var d = new Date();
      var time = d.toISOString();
      // var time = "2020-11-01T08:21:06.856Z"
      var randomString = uint32 + "_" + time + "_" + timestamp;
      localStorage.setItem("userId", randomString);
    }

    var id = localStorage.getItem("userId");
    axios
      .get(`${domain}/api/user_notifications/${id}`)
      .then(function (response) {
        // handle success
        setNotifications(response.data);
       
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  const renderNotifications = () => {
    var notiflist = [];
    notifications.forEach(function (item, index) {
      notiflist.push(
        <li className="notification-block" key={index}>
          <a href={item.url}>
            <Avatar className="notification-avatar">N</Avatar>
            <div className="notification-content-area">
              <p className="notification-title">{item.title}</p>
              <p className="notification-content">{item.detail}</p>
            </div>
          </a>
        </li>
      );
    });
    return notiflist;
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="notification-section">
      <div onClick={handleClick}>
        <img loading="lazy" src={require("../../images/bell.svg")} alt="cart" />
        <small className="notification-badge" id="cart_count_top">
          {notifications.length}
        </small>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="notification ">
          <div className="header-notification">
            <div className="notification-heading-block">
              <NotificationsNoneIcon className="notification-icon" />
              <p className="notification-heading">Notifications</p>
              {/* <InsertCommentIcon className="chat-icon" /> */}
            </div>

            <div className="header-notification-body">
              {notifications.length > 0 ? (
                <ul className="notiful">{renderNotifications()}</ul>
              ) : (
                <Typography className={classes.typography}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "50px",
                      margin: "20px 0 10px 0",
                      color: "#8cb1d7",
                    }}
                  >
                    <i className="far fa-bell-slash"></i>
                  </div>
                  You dont have any notifications at the moment.
                </Typography>
              )}
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
