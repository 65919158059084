import React from "react";
import "./NoDataContent.scss";


export const NoDataContent = (props) =>{
    return(
        <div className="no-data-content">
   <img src={props?.image} alt="notify" />
      <p>{props?.info}</p>     
        </div>
    )
}