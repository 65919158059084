import React from "react";

class FooterShimmer extends React.Component {
  render() {
    return (
      <div className="shim-card">
        <div className="row">
          <div className="col l12 mt-3">
            <div className="col l4">
              <p className="footer-shim-img"></p>
              <p className="footer-shim-desc"></p>
              <p className="footer-more-shim"></p>
            </div>
            <div className="col l8">
              <div className="col l3">
                <h5 className="footer-list-heading-shim"> </h5>
                <ul className="footer-list-shim">
                  <li className="wd-1"> </li>
                  <li className="wd-1"> </li>
                  <li className="wd-3"> </li>
                  <li className="wd-4"> </li>
                </ul>
              </div>
              <div className="col l3">
                <h5 className="footer-list-heading-shim"> </h5>
                <ul className="footer-list-shim">
                  <li className="wd-4"> </li>
                  <li className="wd-3"> </li>
                  <li className="wd-2"> </li>
                  <li className="wd-2"> </li>
                </ul>
              </div>
              <div className="col l3">
                <h5 className="footer-list-heading-shim"> </h5>
                <ul className="footer-list-shim">
                  <li className="wd-2"> </li>
                  <li className="wd-2"> </li>
                  <li className="wd-4"> </li>
                  <li className="wd-1"> </li>
                </ul>
              </div>
              <div className="col l3">
                <h5 className="footer-list-heading-shim"> </h5>
                <ul className="footer-list-shim">
                  <li className="wd-1"> </li>
                  <li className="wd-2"> </li>
                  <li className="wd-3"> </li>
                  <li className="wd-4"> </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col l8 copyright-text">
                <h1 className="copyright-shim"> </h1>
              </div>
              <div className="col l4 left-align-sm">
                <div className="icons-text">
                  <h5 className="copyright-shim-keep"> </h5>
                </div>
                <div className="footer-icons d-flex ">
                  <div className="copyright-shim-icons"></div>
                  <div className="copyright-shim-icons"></div>

                  <div className="copyright-shim-icons"></div>
                  <div className="copyright-shim-icons"></div>
                  <div className="copyright-shim-icons"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterShimmer;
