import produce from "immer";
import * as actionTypes from "../../actions/CategoryListingActions/actionTypes";

const initialState = {
  data: [],
  secondaryData: [],
  secondaryCatProducts: [],
  ternaryData: [],
};

export const categoryListingReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_DATA:
        draft.data = action.payload;
        break;
      case actionTypes.SET_SECONDARY_DATA:
        draft.secondaryData = action.payload;
        break;
      case actionTypes.SET_SECONDARY_CAT_PRODUCTS:
          draft.secondaryCatProducts = action.payload;
        break;
    
      case actionTypes.SET_TERNARY_DATA:
        draft.ternaryData = action.payload;
        break;
      default:
        return draft;
    }
  });


  
