import React, { useState } from "react";
import { postFacebookLogin } from "../../services/userApi";
import FacebookLogin from "react-facebook-login";
import { toastr } from "react-redux-toastr";
import "../../style/FacebookLogin.scss";

export const FacebookLoginPart = ({ closeModal }) => {
  const [click, setClicked] = useState(false);

  const handleCLick = () => {
    setClicked(true);
  };
  const responseFacebook = (response) => {
    var accessdata = {
      access_token: response?.accessToken,
      id: 3,
      name: "Sweta Testing",
      image: "https://lh3.googleusercontent.com/a/AAcHTtcRrfDy4ADfGK-6VBmEi2QzRkBrCGH_QfFVEHRXpqDCXs4=s96-c",
      user_group_id: 3,
    };

    postFacebookLogin(accessdata).then((res) => {
      if (res.status === 200) {
        // setUserId(res.data.data.id);
        closeModal();
        localStorage.setItem("isAuthenticate", true);
        toastr(res.data.message);
      } else {
      }
    });
  };
  return (
    <div className="facebook-login-section">
      <FacebookLogin
        appId="1258555354786170"
        autoLoad={false}
        onClick={handleCLick}
        fields="name,email,picture"
        scope="public_profile,user_friends"
        callback={click && responseFacebook}
        icon="fa-facebook"
      />
    </div>
  );
};
