import React, {} from "react";
import "../../style/CategoriesListing/equipmentPlaceholder.scss";
// import Axios from "axios";

export const EquipmentPlaceholder = () => {
  // const [ setLogo] = useState([]);


  // const getLogo = () => {
  //   Axios.get(
  //     "https://admin.najik.com/api/product/1418-original-blue-dochem/55-medico-phamacy-pvt-ltd"
  //   ).then((res) => {
  //     setLogo(res.data.product[0].suppliers[0].logo);
  //   });
  // };

  return (
    <div className="placeholder-image">
      {/* transparent image for placeholder */}
      <img
        alt="img"
        loading="lazy"
        src={require("../../images/logo-white.png")}
      />
    </div>
  );
};
