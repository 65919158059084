import produce from "immer";
import * as actionTypes from "../../actions/HeaderTitleAction/actionTypes";

const initialState = {
    title: ''
};

export const headerTitleReducer = (state = initialState, action = {}) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.SET_TITLE:
                draft.title = action.payload;
                break;
            default:
                return draft;
        }
    });
