import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../../style/Mobile/MobileInsideHeader.scss";
import { connect } from "react-redux";
// import * as actions from "../../redux/actions/HeaderTitleAction/setTitleAction";
import SearchComponent from "../Home/SearchComponent";
class MobileInsideHeader extends Component {
  render() {
    return (
      <header classname="page-topbar " id="header">
        <div className=" mobile-view-header">
          <div classname="navbar">
            <nav
              className="navbar-main navbar navbar-color nav-collapsible sideNav-lock navbar-light "
              style={{
                position: "fixed",
                top: 0,
                background: "#fff",
                zIndex: 2,
              }}
            >
              <div
                className="container nav-wrapper p-0"
                style={{ textAlign: "center" }}
              >
                <div
                  className="row"
                  style={{
                    lineHeight: "55px",
                    display: "flex",
                    height: "55px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="col s12 p-0"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ height: "100%", width: "15%" }}>
                      <a
                        href
                        className="mobile-back"
                        onClick={() => this.props.history.goBack()}
                        style={{ width: 60 }}
                      >
                        <svg
                          width={19}
                          height={16}
                          viewBox="0 0 19 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.556 7.847H1M7.45 1L1 7.877l6.45 6.817"
                            stroke="#042E59"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="none"
                          />
                        </svg>
                      </a>
                    </span>
                    <h6
                      className="navbar-title m-0"
                      style={{
                        // lineHeight: "55px",
                        color: "#4E4E4E",
                        width: "65%",
                        textAlign: "left",
                      }}
                    >
                      {this.props.title}
                    </h6>

                    <span
                      className="right"
                      style={{ lineHeight: "55px", width: "20%" }}
                    >
                      {/* <a
                        className="search-button"
                        href
                        style={{
                          padding: "20px 5px 16px 15px",
                          position: "relative",
                          top: "0",
                        }}
                        id="search_mobile_icon"
                        >
                        <i className="fa fa-search search-icon"></i>
                      </a> */}
                    </span>
                  </div>
                </div>
              </div>
              <nav
                className="display-none search-sm"
                style={{ zIndex: 1, backgroundColor: "#fff", height: 45 }}
              >
                <div className="nav-wrapper">
                  <form action method="GET" className="newsletter_form ">
                    <div className="input-field">
                      <input
                        className="search-box-sm"
                        type="search"
                        name="q"
                        placeholder="Search for anything, anywhere"
                        required
                      />
                      <label className="label-icon" htmlFor="search">
                        <i
                          className="material-icons search-sm-icon"
                          style={{ height: "30px" }}
                        >
                          search
                        </i>
                      </label>
                      <i
                        className="material-icons search-sm-close"
                        id="close_search_click"
                        style={{ height: "30px" }}
                      >
                        close
                      </i>
                    </div>
                  </form>
                </div>
              </nav>
            </nav>
          </div>
        </div>
        <SearchComponent isMobile={true} />
      </header>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    title: state.headerTitleReducer.title,
  };
};

export default connect(mapStateToProps, null)(withRouter(MobileInsideHeader));
