import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-modal";
import AwesomeModal from "react-awesome-modal";
import { EnquiryForm } from "../../Component/enquiryForm";
import Notification from "./Notification";
import SearchComponent from "./SearchComponent";
import axios from "axios";
import { domain } from "../../url";
import { GoogleLoginPart } from "./GoogleLogin";
import { FacebookLoginPart } from "./FacebookLoginPart";
import "../../style/CategoriesListing/MidHeader.scss";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-25%",
    transform: "translate(-50%, -50%)",
    width: "775px",
    borderRadius: "10px",
    padding: "0",
  },
};

const MidHeader = () => {
  const [modalIsOpen, setModalIsOpen] = useState();
  const [openSupplierModal, setOpenSupplierModal] = useState();
  const [showEnquireyPopup, setShowEnquireyPopup] = useState();
  const [showModal, setShowModal] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [authenticate, setAuthenticate] = useState();
  const [userName, setUserName] = useState();
  const [image, setImage] = useState();
  const [isActive, setIsActive] = useState();

  const history = useHistory();
  const handleLogout = () => {
    localStorage.setItem("jwtToken", "");
    localStorage.setItem("username", "");
    localStorage.setItem("image", "");
    localStorage.setItem("email", "");
    localStorage.setItem("id", "");
    localStorage.setItem("isAuthenticate", false);
    toastr.success("Logout Successfully");
    history.push("/");
  };

  const openModal = () => {
    localStorage.setItem("recentlyViewedProducts", []);
    setModalIsOpen(true);
    setShowModal(true);
  };

  // const getSupplierModal = () => {
  //   setOpenSupplierModal(true);
  //   setShowEnquireyPopup(true);
  // };

  const closeModal = () => {
    setModalIsOpen(false);
    setShowModal(false);
  };

  const postLogin = () => {
    const data = {
      email: email,
      password: password,
      user_group_id: 3,
    };
    axios
      .post(`${domain}api/user_token`, data)
      .then((res) => {
        closeModal();
        localStorage.setItem("jwtToken", res.data.jwt);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("isAuthenticate", true);
        toastr.success("Login Successfull");
        history.push("/");
      })
      .catch((error) => {
        toastr.error("Login error");
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const name = localStorage.getItem("username");
    const image = localStorage.getItem("image");
    debugger;
    setAuthenticate(token);
    setUserName(name);
    setImage(image);
  }, [localStorage.getItem("jwtToken")]);

  const handleUserDetails = () => {
    setIsActive(!isActive);
  };
  const closeSupplierModal = () => {
    setOpenSupplierModal(false);
    setShowEnquireyPopup(false);
  };

  return (
    <div className="mid-header-section">
      <nav
        className="navbar-main navbar-color nav-collapsible sideNav-lock middle-nav"
        id="top"
      >
        {showEnquireyPopup ? (
          <AwesomeModal
            visible={openSupplierModal}
            padding="20px"
            width="400"
            effect="fadeInUp"
            onClickAway={() => closeSupplierModal()}
          >
            <div className="product-popup new-line-height header-popup p-20">
              <EnquiryForm isHomePage={false} closeModal={closeSupplierModal} />
            </div>
          </AwesomeModal>
        ) : (
          ""
        )}
        {showModal ? (
          <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="row" style={{ background: "#F5F7F9" }}>
              <div className="col s6 m6 l6">
                <div className="card popup-card b-r-10">
                  <div className="card-content">
                    <div className="m-0 popup-heading">
                      <h4>How it work?</h4>
                    </div>

                    <div className=" col s12 l12 m12 process">
                      <div className="col m4 l4 popup-img-div">
                        <img
                          loading="lazy"
                          className="popup-img"
                          src={require("../../images/submitYourRequirement.svg")}
                          alt="Not Found"
                        />
                      </div>
                      <div className="col m8 l8  popup-desc">
                        <h4> 01 </h4>
                        <span>
                          Submit your <br />
                          Requirement
                        </span>
                      </div>
                    </div>

                    <div className="col s12 l12 m12 process">
                      <div className="col l4 popup-img-div">
                        <img
                          loading="lazy"
                          className="popup-img"
                          src={require("../../images/sendOffers.svg")}
                          alt="img"
                        />
                      </div>
                      <div className="col l8 popup-desc">
                        <h4> 02 </h4>
                        <span>
                          We Send <br />
                          you Offers
                        </span>
                      </div>
                    </div>

                    <div className="col s12 l12 m12 process">
                      <div className="col l4 popup-img-div">
                        <img
                          loading="lazy"
                          className="popup-img"
                          src={require("../../images/bestDeals.svg")}
                          alt="imgage"
                        />
                      </div>
                      <div className="col l8 popup-desc">
                        <h4> 03 </h4>
                        <span>
                          Choose the <br /> Best Deal
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="col s6 l6 m6 inc-dec-div mt-5">
                    <div className="login-botom-icon">
                      <img
                        loading="lazy"
                        className=""
                        src={require("../../images/earning.svg")}
                        alt="img"
                      />
                    </div>
                    <p className="login-bottom-text">Unlimited Earning</p>
                  </div>
                  <div className="col s6 l6 m6 inc-dec-div mt-5">
                    <div className="login-botom-icon">
                      <img
                        loading="lazy"
                        className=""
                        src={require("../../images/work.svg")}
                        alt="img"
                      />
                    </div>
                    <p className="login-bottom-text">Work From Anywhere</p>
                  </div>
                  <div className="col s6 l6 m6 inc-dec-div mt-5">
                    <div className="login-botom-icon width-71">
                      <img
                        loading="lazy"
                        className=""
                        src={require("../../images/owntime.svg")}
                        alt="img"
                      />
                    </div>
                    <p className="login-bottom-text">Work on your own Time</p>
                  </div>
                </div>
              </div>
              <div
                className="col s6 m6 l6"
                style={{ backgroundColor: "#fff", padding: "24px" }}
              >
                <a href onClick={closeModal} className="right model-close">
                  X
                </a>
                <div>
                  <h3
                    style={{
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "24px",
                      lineHeight: "31px",
                      color: "#042E59",
                    }}
                  >
                    Login
                  </h3>
                  <p className="login-line">
                    Lorem ipsum dolor sit amet, onsectetur adipiscing elit urna
                    amet.
                  </p>
                </div>
                <form>
                  <input
                    type="email"
                    name="username"
                    placeholder="Username or Email"
                    className="input-field-login"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />

                  <input
                    id="password-field"
                    type="password"
                    className="form-control input-field-login"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <span
                    toggle="#password-field"
                    className="fas fa-eye field-icon toggle-password"
                  />
                  <div className="space-between mb-5">
                    <label>
                      <input type="checkbox" />
                      <span className="filled-in login-link ">Remember me</span>
                    </label>
                    <label className="line-height-25">
                      <a href className="login-link ">
                        Forgot Password?
                      </a>
                    </label>
                  </div>

                  <a
                    href
                    type="submit"
                    className="waves-effect waves-green button-login btn-primary"
                    onClick={postLogin}
                  >
                    Login
                  </a>
                </form>
                <div className="center-text">
                  <p className="login-or">or</p>
                </div>
                <a href className="waves-effect waves-green button-social mt-5">
                  <GoogleLoginPart closeModal={closeModal} />
                </a>

                <a href className="waves-effect waves-green button-social mt-5">
                  <FacebookLoginPart closeModal={closeModal} />
                </a>
                <p className="no-ac">
                  Don’t have an account?
                  <a href="/sign-up" className="account-login">
                    Sign Up
                  </a>
                </p>
              </div>
            </div>
          </Modal>
        ) : (
          ""
        )}
        <div className="nav-wrapper container">
          <div className="row middle-nav-row">
            <div className="middle-nav-left">
              <Link to="/" className="brand-logo darken-1">
                <img
                  loading="lazy"
                  src={require("../../images/logo-white.png")}
                  alt="najik"
                />
              </Link>
            </div>
            <div className="middle-nav-center s003 hide-on-med-and-down">
              <form
                action="search"
                method="GET"
                className="hide-on-med-and-down"
                id="searchForm"
              >
                <SearchComponent />
              </form>
            </div>
            {/* 
            <div className=" middle-nav-btn">
              <button
                className="button nav-btn middle-nav-button nav-text waves-effect waves-orange btn-secondary mt--5"
                onClick={() => getSupplierModal()}
              >
                Get Quote
              </button>
            </div> */}

            <div className=" middle-nav-right">
              {/* <img loading="lazy"
                  src={require("../../images/help.svg")}
                  alt="help"
                  className="pl-5"
                /> */}

              {authenticate && <Notification />}
              {/* <Link to="/" className="pl-5">
                  <img loading="lazy" src={require("../../images/bell.svg")} alt="cart" />
                  <small className="notification-badge" id="cart_count_top">
                    0
                  </small>
                </Link> */}
              {authenticate ? (
                <div className="header-login" onClick={handleUserDetails}>
                  {image ? (
                    <img className="user-img" src={image} alt="user" />
                  ) : (
                    <img
                      loading="lazy"
                      src={require("../../images/user-round.svg")}
                      alt="user"
                      className="pr-2"
                    />
                  )}

                  <nav className={`menu ${isActive ? "active" : "inactive"}`}>
                    <ul>
                      <li>
                        <a>
                          {" "}
                          {image ? (
                            <img className="user-img" src={image} alt="user" />
                          ) : (
                            <i class="far fa-user-circle"></i>
                          )}{" "}
                          {userName ? userName : "User"}
                        </a>
                      </li>
                      <li>
                        <a href="/profile-details">
                          {" "}
                          <i class="fas fa-id-card"></i> Profile Details
                        </a>
                      </li>
                      <li>
                        <a onClick={handleLogout}>
                          <i class="fas fa-sign-out-alt"></i> Logout
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              ) : (
                <div className="login-section" onClick={() => openModal()}>
                  <div className="login-btn">
                    <i class="fas fa-sign-in-alt"></i>
                    <p>Login</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <nav className="display-none search-sm">
          <div className="nav-wrapper">
            <form>
              <div className="input-field">
                <input className="search-box-sm" type="search" required />
                <label className="label-icon" htmlFor="search">
                  <i className="material-icons search-sm-icon">search</i>
                </label>
                <i className="material-icons search-sm-close">close</i>
              </div>
            </form>
          </div>
        </nav>
      </nav>
    </div>
  );
};
export default withRouter(MidHeader);
