import React, { useEffect , useState} from "react";
import "./ProfileDetails.scss";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TabDetails } from "../TabDetails/TabDetails";
import { TabProduct } from "../TabProduct/TabProduct";
import { TabProductStatus } from "../TabProductStatus/TabProductStatus";
import { QuotationTab } from "../QuotationTab/QuotationTab";
import Axios from "axios";
import { domain } from "../../url";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const ProfileDetails = () => {
  const [userDetails,setUserDetails] = useState()

  const history = useHistory()
  useEffect(()=>{
    const authenticate = localStorage.getItem("isAuthenticate")
    if(authenticate === "false") {
      history.push("/")
    }

  },[localStorage.getItem("isAuthenticate")])


  useEffect(()=>{

    const fetchdata = async()=>{
      
      try {
        const jwtToken = localStorage.getItem("jwtToken");
        const response = await fetch(`${domain}/api/user_details`, {

         headers: {
           Authorization : `Bearer ${jwtToken}`,
         },
        })
        if(response.ok){
         const responsedata = await response.json();
         setUserDetails(responsedata.user_details)
        }else{
         console.log("error with status code", response.status)
        }
        
     }catch(error){
         console.log("error",error)
     }

    }

    fetchdata()


  },[])
  return (
    <div className="profile-details-section">
     
      <div className="container">

        <div className="card">
          <div className="card-details">
            {userDetails?.image === "https://storage.googleapis.com/najik/" ? 
            <div className="no-user-image">
              {userDetails.name.charAt(0)}
            </div>
            :
           <img
           loading="lazy"
           src={userDetails?.image}
           alt="Not Found"
           className="user-img"
         />
          }
           

            <div className="user-details">
              <p className="user-name">{userDetails?.name}</p>
              <p className="company-address">
                <MailOutlineIcon />
                <span style={{marginLeft:"5px"}}>{userDetails?.email} </span>
              </p>
            </div>
          </div>
        </div>
        <div className="profile-tab-section">
          <Tabs>
            <TabList className="tab-list">
              <Tab>
                <div className="tab-list-wrap">
                  <h5 className="tab-title tab-box">Details</h5>
                </div>
              </Tab>
              <Tab>
                <div className="tab-list-wrap">
                  <h5 className="tab-title tab-box">Quotation Requests</h5>
                </div>
              </Tab>
              <Tab>
                <div className="tab-list-wrap">
                  <h5 className="tab-title tab-box">Products</h5>
                </div>
              </Tab>
              <Tab>
                <div className="tab-list-wrap">
                  <h5 className="tab-title tab-box">Product's Status</h5>
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="tab-panel-card">
              <TabDetails userDetails={userDetails}/>
              </div>
            </TabPanel>

            <TabPanel className="row">
             <QuotationTab/>
            </TabPanel>

            <TabPanel className="row">
             <TabProduct/>
            </TabPanel>

            <TabPanel className="row">
             <TabProductStatus/>
              </TabPanel>
          </Tabs>
        </div>
      </div>
     
    </div>
  );
};


