import React, { useEffect, useState } from "react";
import "../style/ProductDetails/productInfo.scss";
import "../style/main.scss";
import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { domain } from "../url";

const validEmailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const validPhoneRegex = RegExp(/^9(8|6|7)\d{8}$/);

export const EnquiryForm = (props) => {
  const [fullName, setfullName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [productName, setproductName] = useState("");
  const [email, setemail] = useState("");
  const [quantity, setQuantity] = useState("");

  const [errors, setError] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    remark: "",
  });

  useEffect(() => {
    const username = localStorage.getItem("username");
    const useremail = localStorage.getItem("email");
    setfullName(username);
    setemail(useremail);
  }, [localStorage.getItem("username")]);

  useEffect(() => {
    setproductName(props.productId?.name);
  }, [props]);

  const jwtToken = localStorage.getItem("jwtToken");

  var headers = {};

  if (jwtToken) {
    headers = {
      Authorization: `Bearer ${jwtToken}`,
    };
  }
  const [remark, setremark] = useState("");
  // const checkValidation = () => {};
  const submit = async () => {
    var formdata = {
      supplier_name: props.getLatestPrice ? null : props.suppilerId,
      product_name: props.productId?.name,
      contact: phoneNumber,
      name: fullName,
      email: email ?? null,
      status_id: "2",
      supplier_id: "1",
      description: remark,
      quantity,
      category_id: props?.productId?.category_id,
      product_id: props?.productId?.id,
    };
    if (fullName === "") {
      toastr.error("Please Enter your name");
    } else if (email === "") {
      toastr.error("Please Enter your Email");
    } else if (productName === "") {
      toastr.error("Please enter the product you are looking for");
    } else if (errors.email !== "") {
      toastr.error("Invalid Email");
    } else if (phoneNumber === "" || errors.phoneNumber !== "") {
      toastr.error("Invalid Contact Number");
    } else {
      try {
        var { data } = await Axios.post(
          `${domain}api/supplier_inquiry_form`,
          formdata,
          {
            headers: headers,
          }
        );

        if (data.status === "success") {
          toastr.success("Successfully Submitted");
          !props.isHomePage && props.closeModal();
          setphoneNumber("");
          setremark("");
          setproductName("");

          setQuantity("");
        } else {
          toastr.error("Failed to submit");
          !props.isHomePage && props.closeModal();
        }
      } catch (e) {
        toastr.error("Failed to submit");
        !props.isHomePage && props.closeModal();
      }
    }
  };
  return (
    <div className="details">
      <div className="details-contact-block">
        <div className="contact-block">
          {props.productId?.name ? (
            <div>
              <h4 className="product-popup-title">{props.productId.name}</h4>
              <input
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                type="number"
                placeholder="Enter Quantity"
                className="contact-input"
              />
            </div>
          ) : props.supplierId ? (
            ""
          ) : (
            <div className="row no-margin">
              <input
                required
                value={productName}
                onChange={(e) => setproductName(e.target.value)}
                type="text"
                placeholder="Enter product/service"
                className="contact-input"
              />

              <input
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                type="number"
                placeholder="Quantity"
                className="contact-input"
              />
            </div>
          )}
          <input
            value={fullName}
            disabled={localStorage.getItem("username")}
            onChange={(e) => {
              errors.fullName =
                e.target.value.length < 5
                  ? "Full Name must be at least 5 characters long!"
                  : "";
              setfullName(e.target.value);
            }}
            className="contact-input"
            placeholder="Full Name"
          />
          {errors.fullName.length > 0 && (
            <span className="error">{errors.fullName}</span>
          )}
          {/* {props.isHomePage && ( */}
          <input
            className="contact-input"
            disabled={localStorage.getItem("email")}
            placeholder="Email"
            value={email}
            type="email"
            name="email"
            onChange={(e) => {
              errors.email = validEmailRegex.test(e.target.value)
                ? ""
                : "Email is not valid!";
              setemail(e.target.value);
            }}
          />
          {/* )} */}
          {errors.email.length > 0 && (
            <span className="error">{errors.email}</span>
          )}
          <div className="input-field">
            <input
              required
              type="number"
              name="country_code"
              value={phoneNumber}
              pattern="[1-9]{1}[0-9]{9}"
              onChange={(e) => {
                const error = { ...errors };

                setError(error);
                if (e.target.value.length <= 10) {
                  error.phoneNumber = validPhoneRegex.test(e.target.value)
                    ? ""
                    : "Phone Number not valid";
                  setphoneNumber(e.target.value);
                }
              }}
              inputmode="decimal"
              className="contact-input"
              placeholder="Phone Number"
              maxLength="10"
            />
            {errors.phoneNumber.length > 0 && (
              <span className="error">{errors.phoneNumber}</span>
            )}
            <div className="input-field-image">
              <img
                loading="lazy"
                src={require("../images/flag.png")}
                alt="Not Found"
              />
              <span>+977</span>
            </div>
          </div>

          <textarea
            value={remark}
            onChange={(e) => setremark(e.target.value)}
            className="contact-input"
            placeholder="Briefly describe what you are looking for..."
          />
          <button onClick={() => submit()} className="main-btn">
            Submit Requirement
          </button>
        </div>
      </div>
    </div>
  );
};
