import produce from "immer";
import * as actionTypes from "../../actions/HomepageActions/actionTypes";

const initialState = {
    categoryGroups: []
};

export const categoryGroupReducer = (state = initialState, action = {}) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.SET_CATEGORY_GROUPS:
                draft.categoryGroups = action.payload;
                break;

            default:
                return draft;
        }
    });
