import React from "react";
import "../assets/css/materialize.css";
import "../assets/css/dashboard.css";
import "../assets/css/style.css";
import "../assets/css/choices.css";
import "../assets/css/custom.css";
import "../assets/css/style-horizontal.css";
import FooterShimmer from "./HomePage/FooterShimmer";

class Footer extends React.Component {
  footer = () => {
    return (
      <>
        {this.props.isShimmer ? (
          <>
            <FooterShimmer />
          </>
        ) : (
          <div>
            <div className="row">
              <div className="col l12 mt-3">
                <div className="col l4">
                  <img
                    loading="lazy"
                    className="footer-logo"
                    src={require("../images/logo-white.png")}
                    alt="materialize logo"
                  />
                  <p>
                    Najik.com is a one-stop eCommerce platform company based in
                    Jhamshikhel, Lalitpur. Our team of professionals specializes
                    in all kinds of B2C, B2B, Merchandise, and Services.
                  </p>
                  <a className="footer-more" href>
                    Read More...
                  </a>
                </div>
                <div className="col l8">
                  <div className="col l3">
                    <h5 className="list-heading">Policy info</h5>
                    <ul className="footer-list">
                      <li>Security </li>
                      <li>Privacy</li>
                      <li> Return Policy</li>
                      <li>Privacy Policy</li>
                      <li>Terms of Sale</li>
                      <li>Terms of Use</li>
                    </ul>
                  </div>
                  <div className="col l3">
                    <h5 className="list-heading">About Us</h5>
                    <ul className="footer-list">
                      <li>Contact Us </li>
                      <li>About Us</li>
                      <li> Careers</li>
                      <li>Najik Stories</li>
                      <li>Blog</li>
                      <li>Sitemap</li>
                    </ul>
                  </div>
                  <div className="col l3">
                    <h5 className="list-heading">Need Help?</h5>
                    <ul className="footer-list">
                      <li>Payments </li>
                      <li>Shipping</li>
                      <li> Cancellation/Returns</li>
                      <li> FAQ</li>
                      <li>Report Infringement</li>
                    </ul>
                  </div>
                  <div className="col l3">
                    <h5 className="list-heading">Contact Us</h5>
                    <ul className="footer-list">
                      <li>Merchant Support </li>
                      <li>Bulk Orders</li>
                      <li> Be our Agent </li>
                      <li> Be our Supplier </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copyright">
              <div className="container">
                <div className="row">
                  <div className="col l8 copyright-text">
                    <span>
                      {" "}
                      Copyright© 2020,
                      <a
                        className="footer-copyright-link"
                        href="https://najik.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Najik Pvt.Ltd
                      </a>
                      All rights reserved.
                    </span>
                  </div>
                  <div className="col l4 left-align-sm">
                    <div className="icons-text">
                      <h5>Keep In Touch</h5>
                    </div>
                    <div className="footer-icons  ">
                      <a className="single-icon" href>
                        <img
                          loading="lazy"
                          src={require("../images/facebook.svg")}
                          alt="img"
                        />
                      </a>
                      <a className="single-icon" href>
                        <img
                          loading="lazy"
                          src={require("../images/instagram.svg")}
                          alt="img"
                        />
                      </a>
                      <a className="single-icon" href>
                        <img
                          loading="lazy"
                          src={require("../images/linkedin.svg")}
                          alt="img"
                        />
                      </a>
                      <a className="single-icon" href>
                        <img
                          loading="lazy"
                          src={require("../images/twitter.svg")}
                          alt="img"
                        />
                      </a>
                      <a className="single-icon" href>
                        <img
                          loading="lazy"
                          src={require("../images/youtube.svg")}
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <footer
        className="page-footer footer footer-static gradient-shadow navbar-border navbar-shadow"
        style={{
          background:
            "linear-gradient(268.47deg, #3FB84B -10.67%, #065DC4 141.56%, #0075FF 141.56%)",
        }}
      >
        {this.footer()}
      </footer>
    );
  }
}

export default Footer;
