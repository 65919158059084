import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { EquipmentPlaceholder } from "../pages/CategoriesListing/equipmentPlaceholder";
const ImageLoader = ({ images, className }) => {
  let image;
  if (typeof images === "object") {
    image = images?.[0];
  } else {
    image = images;
  }

  return image == null || image === undefined || image === "No Image" ? (
    <EquipmentPlaceholder />
  ) : (
    <LazyLoadImage
      alt={"image"}
      effect="blur"
      src={image}
      className={className}
    />
  );
};

export default ImageLoader;
