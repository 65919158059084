import React, { Component } from "react";
import "../../assets/css/materialize.css";
import "../../assets/css/dashboard.css";
import "../../assets/css/style.css";
import "../../assets/css/choices.css";
import "../../assets/css/custom.css";
import "../../assets/css/style-horizontal.css";
import "../../style/dealOfTheWeek.scss";

class BottomCardShimmer extends Component {
  render() {
    return (
      <div className="container bottomcard-shim">
        <div className="row">
          <div className="col s12 m12 l12 p-0">
            <div className=" card  b-r-12">
              <div className="card-content col l3 s6 p-0 shim-card">
                <div className="bottom-cards center-align-sm bottom-cart-b-1 bottomcardshim-content">
                  <p className="bottomcard-img-shim"></p>
                  <p className="bottomcard-img-name"></p>
                </div>
              </div>
              <div className="card-content col l3 s6 p-0 shim-card">
                <div className="bottom-cards center-align-sm bottom-cart-b-1 bottomcardshim-content">
                  <p className="bottomcard-img-shim"></p>
                  <p className="bottomcard-img-name"></p>
                </div>
              </div>
              <div className="card-content col l3 s6 p-0 shim-card">
                <div className="bottom-cards center-align-sm bottom-cart-b-1 bottomcardshim-content">
                  <p className="bottomcard-img-shim"></p>
                  <p className="bottomcard-img-name"></p>
                </div>
              </div>
              <div className="card-content col l3 s6 p-0 shim-card">
                <div className="bottom-cards center-align-sm bottom-cart-b-1 bottomcardshim-content">
                  <p className="bottomcard-img-shim"></p>
                  <p className="bottomcard-img-name"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BottomCardShimmer;
