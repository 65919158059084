import React, { useEffect, useState } from "react";
import "./QuotationTab.scss";
import Axios from "axios";
import { domain } from "../../url";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import NoQuotation from "../../images/notify.svg";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";

export const QuotationTab = () => {
  const [details, setDetails] = useState();
  const history = useHistory();

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const jwtToken = localStorage.getItem("jwtToken");
        const response = await fetch(`${domain}/api/supplier_inquiries`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (response.ok) {
          const responsedata = await response.json();
          setDetails(responsedata);
        } else {
          console.log("error with status code", response.status);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    dataFetch();
  }, []);

  const handleOpenChat = (itm) => {
    const transformedData = {
      chat: {
        id: itm?.user_id,
        user_names: [
          {
            ...itm,
            name: itm.company_name,
            id: itm?.user_id,
          },
        ],
      },
    };
    history.push({
      pathname: `/chat/${itm?.id}`,
      state: {
        supplierDetails: transformedData,
      },
    });
  };
  return (
    <div className="quotation-tab">
      {details?.inquiries ? (
        <>
          {details?.inquiries?.map((item, index) => {
            return (
              <div className="tab-panel-card " key={index}>
                <div className="details-wraper">
                  <div className="sub-details-wrapper">
                    <div className="quotation-content" style={{ width: "40%" }}>
                      <p className="title-content mb-20">Product Name</p>
                      <p className="title-content mb-20">Quantity</p>
                      <p className="title-content mb-20">Supplier</p>
                    </div>

                    <div
                      className="quotation-content border-left"
                      style={{ width: "60%" }}
                    >
                      <p className="medium-content mb-20">
                        {item?.product_name}
                      </p>
                      <p className="medium-content mb-20">{item?.quantity}</p>

                      {item?.suppliers ? (
                        <>
                          {item?.suppliers[0]?.map((itm, idx) => {
                            return (
                              <div className="supplier-details">
                                <div className="supplier-head">
                                  <p className="bold-content">
                                    {itm?.company_name}
                                  </p>
                                  <div className="ratings">
                                    4.1/5{" "}
                                    <span>
                                      <i class="fas fa-star"></i>
                                    </span>
                                  </div>
                                </div>

                                <h5 className="supplier-bottom location">
                                  <i class="fas fa-map-marker-alt"></i>
                                  <span>{itm?.address}</span>
                                </h5>

                                <div
                                  className="chat-block"
                                  onClick={() => {
                                    handleOpenChat(itm);
                                  }}
                                >
                                  <i class="far fa-comment-dots"></i>
                                  <p className="content">Chat with us!</p>
                                </div>

                                {!itm?.is_verified && (
                                  <h5 className="supplier-bottom verified">
                                    <i class="fas fa-check-circle"></i>
                                    <span>Verified Supplier</span>
                                  </h5>
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>

                  <div className="sub-details-wrapper">
                    <div
                      className="quotation-content border-left text-center"
                      style={{ width: "40%" }}
                    >
                      <p className="bold-content mb-20">Status</p>
                      {item?.status_name === "Completed" ? (
                        <i class="far fa-check-circle mb-20"></i>
                      ) : (
                        <i class="far fa-clock"></i>
                      )}

                      <p className="medium-content">{item?.status_name}</p>
                    </div>

                    <div
                      className="quotation-content border-left"
                      style={{ width: "60%" }}
                    >
                      <p className="bold-content mb-20">Description</p>
                      <p className="title-content mb-20">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        ></div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <NoDataContent
          image={NoQuotation}
          info="You haven't requested for quotation yet "
        />
      )}
    </div>
  );
};
