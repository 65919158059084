/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/css/materialize.css";
import "./assets/css/dashboard.css";
import "./assets/css/style.css";
import "./assets/css/choices.css";
import "./assets/css/custom.css";
import "./assets/css/style-horizontal.css";
import "../src/style/ProductListing/sideBar.scss";
// import HomePage from "./pages/HomePage/DesktopView/index";
import Footer from "./pages/footer";
import Headerr from "./pages/Header";
// import SidenavMobile from "./pages/Home/SidenavMobile";
import { Route, Switch, useHistory } from "react-router-dom";
import BottomCard from "./pages/Home/bottomcard";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// import CategoriesListing from "./pages/CategoriesListing/i/ndex";
// import SupplierProfile from "./pages/SupplierProfile";
import { isMobile } from "react-device-detect";
import logo from "./images/blue_najik_logo.svg";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Suspense } from "react";
import LoadingPage from "./pages/PreLoader/loadingPage";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ProfileDetails } from "./pages/ProfileDetails/ProfileDetails";
import { gapi } from "gapi-script";
import { Chat } from "./pages/Chat/Chat";

const ProductListing = React.lazy(() => import("./pages/ProductListng"));
const SinglePage = React.lazy(() =>
  import("./pages/SingleaProductPage/SingleProductPage")
);
const CartPage = React.lazy(() => import("./pages/CartPage/CartPage"));
const CategoriesListing = React.lazy(() =>
  import("./pages/CategoriesListing/index")
);
const SupplierProfile = React.lazy(() => import("./pages/SupplierProfile"));
const ProductDetails = React.lazy(() => import("./pages/ProductDetails"));
const LandingPage = React.lazy(() => import("./pages/LandingPage/index"));
const Home = React.lazy(() => import("./pages/Home/Home"));
const LoginPage = React.lazy(() =>
  import("./pages/HomePage/MobileView/LoginPage/loginPage")
);
const LoginDashboard = React.lazy(() =>
  import("./pages/HomePage/MobileView/LoginPage/loginDashboard")
);
const AgentRegister = React.lazy(() =>
  import("./pages/AgentRegister/agentRegister")
);
const SupplierRegister = React.lazy(() =>
  import("./pages/SupplierResister/supplierRegister")
);
const HomeComponent = React.lazy(() => import("./pages/HomePage/index"));
const clientId =
  "75130792214-cniujgiogdim368plqoree5hb1cjdcip.apps.googleusercontent.com";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24 * 7,
      cacheTime: 1000 * 60 * 60 * 24 * 7,
    },
  },
});

const scrollPositions = {};
let prevScroll = 0;
const App = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    //please let me keep this here -Subham
    sessionStorage.removeItem("activeItem");
  }, []);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    document.addEventListener(
      "scroll",
      (e) => {
        prevScroll = e.target.scrollTop;
        scrollPositions[window.location.pathname] = [
          window.pageXOffset,
          e.target.scrollTop,
        ];
      },
      true
    );
  }, []);

  useEffect(() => {
    const { pathname = "root" } = location;
    if (history.action === "POP") {
      const pos = scrollPositions[pathname];
      if (pos) {
        if (pathname.includes("/product-listing/"))
          setTimeout(() => document.body.scrollTo(pos[0], pos[1]), 1100);
        // For infinite scroll takes some time
        else setTimeout(() => document.body.scrollTo(pos[0], pos[1]), 100);
      }
    } else {
      scrollPositions[pathname] = [window.pageXOffset, prevScroll];
      setTimeout(() => window.document.body.scrollTo(0, 0), 100);
    }
  }, [location, history.action]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
        <MainPage />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
const MainPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isShimmer, setIsShimmer] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setIsShimmer(false);
    }, 200);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div
        className={
          isLoading ? "fullpageloader showLoader" : "fullpageloader hideLoader"
        }
      >
        <img loading="lazy" src={logo} alt="img" />
      </div>

      <Headerr />
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path="/home" component={Home}></Route>
          <Route
            exact
            path="/login_dashboard"
            component={LoginDashboard}
          ></Route>
          <Route exact path="/login" component={LoginPage}></Route>
          <Route
            exact
            path="/primary/:categoryId"
            component={CategoriesListing}
          ></Route>
          <Route
            exact
            path="/product-listing/:category"
            component={ProductListing}
          ></Route>
          <Route
            exact
            // path="/product-details/:productId/:supplierId"
            path="/product-details/:productId"
            component={ProductDetails}
          ></Route>

          <Route
            exact
            path="/supplier-profile/:supplierSlug"
            component={SupplierProfile}
          ></Route>

          <Route exact path="/" component={HomeComponent}>
            {/* <HomePage /> */}
          </Route>
          <Route
            exact
            path="/profile-details"
            component={ProfileDetails}
          ></Route>
          <Route exact path="/loading" component={LoadingPage}></Route>
          <Route exact path="/home-page" component={LandingPage}></Route>
          <Route
            exact
            path="/business/products/:product"
            component={SinglePage}
          />
          <Route exact path="/cart" component={CartPage}></Route>
          <Route exact path="/register" component={SupplierRegister}></Route>
          <Route exact path="/sign-up" component={AgentRegister}></Route>
          <Route exact path="/chat/:slug" component={Chat}></Route>
        </Switch>
      </Suspense>
      {!isMobile && <BottomCard />}
      {!isMobile && <Footer isShimmer={isShimmer} />}
    </div>
  );
};
