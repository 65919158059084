import React from "react";
import "../../style/main.scss";
import "../../style/PreLoader/loadingPage.scss";

const LoadingPage = () => {
  return (
    <div className="container loading">
      <div className="row">
        <div className="col l12">
          <div className="loader">
            <div className="loader-inner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
