import React, { useState } from "react";
import { postGoogleLogin } from "../../services/userApi";
import GoogleLogin from "react-google-login";
import { toastr } from "react-redux-toastr";
import "../../style/GoogleLogin.scss";

export const GoogleLoginPart = ({ closeModal }) => {
  // const [click,setClicked]= useState(false)

  const clientId =
    "75130792214-cniujgiogdim368plqoree5hb1cjdcip.apps.googleusercontent.com";

  // const handleCLick =()=>{
  //   setClicked(true)
  // }

  const onSuccess = (res) => {
    responseGoogle(res);
  };

  const responseGoogle = (res) => {
    localStorage.setItem("image", res.profileObj.imageUrl);
    debugger;
    var postData = {
      name: res.profileObj.name,
      image: res.profileObj.imageUrl,
      email: res.profileObj.email,
      token_id: res.tokenId,
      user_group_id: 3,
    };
    postGoogleLogin(postData).then((response) => {
      debugger;
      if (response.status === 200) {
        localStorage.setItem("jwtToken", response.data.data.jwt);
        localStorage.setItem("id", response.data.data.id);
        localStorage.setItem("username", response.data.data.name);
        localStorage.setItem("email", response.data.data.email);
        localStorage.setItem("isAuthenticate", true);
        closeModal();
        toastr.success(response.data.message);
      } else {
        toastr.error("something went wrong");
      }
    });
  };

  const onFailure = (res) => {
    console.log("login failure", res);
  };

  return (
    <div className="google-login-section">
      <GoogleLogin
        autoLoad={false}
        clientId={clientId}
        buttonText={"login with google"}
        // onClick={handleCLick}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={false}
      />
    </div>
  );
};
