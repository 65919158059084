import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./header.css";
import { HamBurger } from "./hamBurger";
import Notification from "../../Home/Notification";
import SearchComponent from "../../Home/SearchComponent";

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     // marginRight: "-25%",
//     transform: "translate(-50%, -50%)",
//     width: "775px",
//     borderRadius: "10px",
//     padding: "0",
//   },
// };

const searchStyle = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    marginLeft: "20px",
    marginRight: "20px",
    width: "88%",
  }),
  control: (provided) => ({
    ...provided,
    margin: "0 20px",
    border: "none ",
    display: " flex",
    height: "50px",
    background: "white",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "50px",
  }),
  placeholder: (provided) => ({
    ...provided,
    top: "50%",
    fontSize: "12px",
    lineHeight: "12px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    lineHeight: "35px",
    paddingTop: "0",
    color: "black",
  }),
  // menu: (provided) => ({
  //   ...provided,
  //   marginLeft: "20px",
  //   marginRight: "20px",
  //   width: "88%",
  //   zIndex: "10",
  // }),
  menuList: (provided) => ({
    ...provided,
    color: "black",
    lineHeight: "20px",
  }),
};

class Header extends Component {
  state = {
    modalIsOpen: false,
    openSupplierModal: false,
  };

  render() {
    return (
      <div className="header">
        <header className="page-topbar" id="header">
          <div className="navbar">
            <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark">
              <div className="nav-wrapper">
                <ul className="left">
                  <li>
                    <h1 className="logo-wrapper">
                      <a className="brand-logo darken-1" href="/">
                        <img
                          loading="lazy"
                          className="popup-img"
                          src={require("../../../images/logo-white.png")}
                          alt="img"
                        />
                      </a>
                    </h1>
                  </li>
                </ul>

                <ul className="navbar-list right">
                  <li>
                    <Notification />
                    {/* <a
                      className="notification-button"
                      href="javascript:void(0);"
                      data-target="notifications-dropdown"
                    >
                      <img loading="lazy"
                        src={require("../../../images/bell.svg")}
                        alt="img"
                      />
                    </a> */}
                  </li>
                  <li>
                    <Link to="/login_dashboard">
                      <div
                        className="profile-button"
                        data-target="profile-dropdown"
                      >
                        <span className="avatar-status avatar-online">
                          <img
                            loading="lazy"
                            src={require("../../../images/user-round.svg")}
                            alt="avatar"
                          />
                          <i />
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
                {/* translation-button*/}
                <ul className="dropdown-content" id="translation-dropdown">
                  <li>
                    <a className="grey-text text-darken-1" href>
                      <i className="flag-icon flag-icon-gb" /> English
                    </a>
                  </li>
                  <li>
                    <a className="grey-text text-darken-1" href>
                      <i className="flag-icon flag-icon-fr" /> French
                    </a>
                  </li>
                  <li>
                    <a className="grey-text text-darken-1" href>
                      <i className="flag-icon flag-icon-cn" /> Chinese
                    </a>
                  </li>
                  <li>
                    <a className="grey-text text-darken-1" href>
                      <i className="flag-icon flag-icon-de" /> German
                    </a>
                  </li>
                </ul>
                <div className="clearfix"></div>
                <div className="col md-12">
                  <SearchComponent searchStyle={searchStyle} isMobile={true} />
                  <i className="fa fa-search search-icon"></i>
                </div>
              </div>
            </nav>
          </div>
        </header>

        <HamBurger isHeader={true} />

        {/* <SidenavMobile /> */}
      </div>
    );
  }
}

export default withRouter(Header);
