import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { productReducer } from "../reducers/productReducer";
import { categoryGroupReducer } from "../reducers/HomepageReducer/categoryGroupsReducer";
import { categoryListingReducer } from "../reducers/CategoryListingReducer/categoryListingReducer";
import { headerTitleReducer } from "../reducers/HeadertitleReducer/HeaderTitleReducer";
import { userReducer } from "../reducers/userReducer";
import { reducer as toastrReducer } from "react-redux-toastr";

const initialState = {};

const middleware = [thunk];

const rootReducer = combineReducers({
  headerTitleReducer,
  categoryGroupReducer,
  productReducer,
  userReducer,
  categoryListingReducer,
  toastr: toastrReducer,
});

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
