import produce from "immer";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  agent: [],
};

export const userReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_AGENT:
        draft.agent = action.payload;
        break;
      default:
        return draft;
    }
  });
