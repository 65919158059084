import axios from "axios";
import { toastr } from "react-redux-toastr";
import { domain } from "../../url";
const instance = axios.create({
  baseURL: domain,
});

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    toastr.error("Something went wrong", error.message);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    toastr.error("Something went wrong", error.message);
    return Promise.reject(error);
  }
);
export default instance;
