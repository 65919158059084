import axios from "./axiosInstance/najikAdmin";

export const agentRegister = async (userInfo) => {
  const data = await axios.post("/api/user_registration", userInfo);
  return data;
};

// export const postFacebookLogin = data => {
//   const data = await axios.post("fb_login", data);
//   return data;
// };

export const postFacebookLogin = async (data) => {
  const response = await axios.post("api/fb_login", data);
  return response;
};

export const postGoogleLogin = data => {
  return axios.post("api/google_login", data);
};