import produce from "immer";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  category: [],
  singleProductAndRelated: {},
  products: [],
  filters: {},
};

export const productReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_CATEGORY:
        draft.category = action.payload;
        break;
      case actionTypes.FETCH_SINGLE_RELATED_PRODUCT:
        draft.singleProductAndRelated = action.payload;
        break;
      case actionTypes.SET_PRODUCTS:
        draft.products = action.payload;
        break;
      case actionTypes.MANAGE_FILTERS:
        draft.filters[action.payload.filterKey] = action.payload.filterVal;
        break;
      case actionTypes.SET_NO_OF_PRODUCTS:
        draft.category["No of Products"] = action.payload;
        break;
      case actionTypes.SET_NO_OF_PAGES:
        draft.category.Pages = action.payload;
        break;
      default:
        return draft;
    }
  });
